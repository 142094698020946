import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import { RichTextReact } from "../../RichTextReact/RichTextReact";
import { RichText } from "../../../classes/RichText";
//import Theme from "../../../vars/ThemeOptions";

const padTop = 12;
const margTop = (3 / 4) * padTop;

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  padding-top: ${({ richText1 }) => richText1 ? `${padTop}rem` : '6rem'};
  margin-top: -${margTop}rem;
  padding-bottom: 1.5rem;
  background: linear-gradient(
    to right,
    rgba(26, 31, 54, 1) 0%,
    rgba(26, 31, 54, 1) 30%,
    rgba(26, 31, 54, 0.6) 70%,
    white 100%
  );
  color: white;
  h1 {
    font-size: 2.25rem;
  }
  b {
    color: #ff97a8;
  }
  i {
    font-style: normal;
    font-weight: bold;
    color: white;
    background: #ff97a8;
    padding: 0 10px;
    margin-left: 10px;
  }
  p {
    font-size: 19.9px;
    font-weight: 500;
  }
  @media only screen and (max-width: 1199px) {
    h1 {
      font-size: 2.5rem;
    }
  }
  @media only screen and (max-width: 991px) {
    padding-top: ${padTop * 1.5}rem;
    margin-top: -${margTop * 1.5}rem;
    background: linear-gradient(
      to right,
      rgba(26, 31, 54, 1),
      rgba(26, 31, 54, 0.7)
    );
    i {
      display: inline-block;
      margin-left: 0px !important;
    }
    p {
      font-size: 1rem;
      margin-top: 1.5rem;
    }
  }
`;

const PigHero = ({ data }) => {
  // console.log('data: ', data);
  const richText1 = get(data, "richText1");
  const rt = new RichText(richText1);
  return (
    <Wrap richText1={richText1}>
      <Container>
        <Row>
          <Col>
            <RichTextReact content={rt} />
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default PigHero;
