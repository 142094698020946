import React, { useRef } from "react";
import styled from "styled-components";
import { NetlifyForm } from "wmk-netlify-form";
import Theme from "../../../vars/ThemeOptions";

const Wrap = styled.div`
  width: 30rem;
  &.sticky {
    position: fixed;
    top: 2rem;
    &.bottom {
      position: relative;
      top: 31rem;
    }
    .optional {
      right: 0;
      transition: all 0s ease;
    }
  }
  label {
    display: none;
    &.show {
      display: block;
      margin: 1rem 0 -0.5rem 0.5rem;
      margin-top: 1.5rem;
      margin-bottom: -0.5rem;
    }
  }
  .form-check {
    margin: 0.25rem 0 0.5rem 0.5rem;
    label {
      display: block;
      position: relative;
      top: 0.65rem;
    }
  }
  select {
    padding: 0.375rem 0.5rem;
  }
  margin-top: 2rem;
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    span {
      color: #ff97a8;
    }
  }
  input,
  select,
  .form-control {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid ${Theme.hex("primary")};
    margin-top: 1rem;
    &:active,
    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${Theme.hex("primary")};
    }
    ::placeholder,
    option {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }
  label {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  .optional {
    position: absolute;
    z-index: 10;
    right: 5rem;
    text-align: right;
    font-size: 0.8rem;
    transition: all 0s ease;
  }
  .submit-col {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    button {
      border-radius: 0;
      border: 0;
      background: ${Theme.hex("primary")};
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    &.sticky,
    &.bottom {
      position: relative;
      top: 0 !important;
      .optional {
        right: 0;
      }
    }
  }
`;

const JCCIntakeForm = ({ title, fields, config }) => {
  const formRef = useRef();

  return (
    <Wrap ref={formRef}>
      <NetlifyForm title={title} fields={fields} config={config} />
    </Wrap>
  );
};

export default JCCIntakeForm;
