import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import get from "lodash/get";
import PigHero from "../LPPigPromo/PigHero";
import JCCIntakeForm from "./Form";

const Wrap = styled.div`
  * {
    transition: all 0.3s ease;
  }
  padding: 2rem 0;
`;

export const JCCLeadStatusOptions = [
  `Appointment Scheduled`,
  `Needs Further Qualification`,
  `Disqualified`
];
export const serviceInterestOptions = [
  `Partial Siding Replacement`,
  `Full Siding Replacement`,
  `Siding Repair`,
  `Window Installation`,
  `Hail Damage Siding`,
  `Vinyl Siding Installation`,
  `Need Help Determining`
];
export const howDidHearOptions = [
  "Google Search",
  "Facebook",
  "LinkedIn",
  "Instagram",
  "Neighbor",
  "NextDoor",
  "Special Event",
  "Radio",
  "5280 Magazine",
  "Personal Referral",
  "Networking Group",
  "Yelp",
  "BBB",
  "Other"
];
export const sidingTypeOptions = [
  "James Hardie Siding",
  "Cedar Siding",
  "Composite Wood Siding",
  "Vinyl Siding",
  "Metal Siding",
  "Unsure",
  "Whatever I've got now!",
  "Open to Suggestions"
];
export const whenToStartOptions = [
  `1-2 Weeks`,
  `More than 2 Weks`,
  `Timing is Flexible`
];

const LpJccIntakeFormPage = ({
  title = "JCC Intake Form",
  formName = "JCCIntakeForm",
  thanks = "/jcc-intake-form-success",
  leadStatus = "JCC Lead Status",
  springHomeShow
}: {
  title?: string;
  formName: string;
  thanks?: string;
  leadStatus?: string;
  springHomeShow?: boolean;
}) => {
  // useEffect(() => {
  //   window.location.href = "https://coloradosidingrepair.com/sales/denver-home-show/";
  // }, [])
  const data = useStaticQuery(graphql`
    query {
      heroData: contentfulGlobal(title: { eq: "LP Home Show - Hero Content" }) {
        ...NodeGlobalFields
      }
    }
  `);
  const heroData = get(data, "heroData");
  const fields = !springHomeShow
    ? [
        { as: `name` },
        { as: `email` },
        { as: `phone` },
        { as: `address` },
        {
          as: `single`,
          props: {
            name: `companyRep`,
            label: `Company Representative`
          }
        },
        {
          as: `select`,
          props: {
            name: `jccLeadStatus`,
            label: leadStatus,
            options: JCCLeadStatusOptions,
            isRequired: true
          }
        },
        {
          as: `select`,
          props: {
            name: `fullOrPartial`,
            label: `Which service are you interested in?`,
            options: serviceInterestOptions,
            isRequired: false
          }
        },
        {
          as: `select`,
          props: {
            name: `howDidYouHearAboutUs`,
            label: `How did you hear about us?`,
            options: howDidHearOptions,
            isRequired: false
          }
        },
        {
          as: `single`,
          props: {
            name: `appointmentDateAndTime`,
            label: `Appointment Date/Time`
          }
        },
        {
          as: `select`,
          props: {
            name: `desiredSidingType`,
            label: `Select a Siding Type`,
            options: sidingTypeOptions,
            isRequired: false
          }
        },
        {
          as: `checkbox`,
          props: {
            name: `financing`,
            label: `Interested in Financing?`,
            text: `Yes, send me information about applying for financing.`
          }
        },
        { as: `message` }
      ]
    : [
        { as: `name` },
        { as: `email` },
        { as: `phone` },
        { as: `address` },
        {
          as: `single`,
          props: {
            name: `companyRep`,
            label: `Company Representative`
          }
        },
        {
          as: `select`,
          props: {
            name: `jccLeadStatus`,
            label: leadStatus,
            options: JCCLeadStatusOptions,
            isRequired: true
          }
        },
        {
          as: `select`,
          props: {
            name: `fullOrPartial`,
            label: `Which service are you interested in?`,
            options: serviceInterestOptions,
            isRequired: false
          }
        },
        {
          as: "hidden",
          props: {
            name: `howDidYouHearAboutUs`,
            value: `Spring Home Show`
          }
        },
        {
          as: `single`,
          props: {
            name: `appointmentDateAndTime`,
            label: `Appointment Date/Time`
          }
        },
        {
          as: `select`,
          props: {
            name: `desiredSidingType`,
            label: `Select a Siding Type`,
            options: sidingTypeOptions,
            isRequired: false
          }
        },
        {
          as: `checkbox`,
          props: {
            name: `financing`,
            label: `Interested in Financing?`,
            text: `Yes, send me information about applying for financing.`
          }
        },
        { as: `message` }
      ];
  return (
    <Wrap>
      <PigHero data={heroData} />
      <Container>
        <Row>
          <Col
            lg={12}
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center"
            }}>
            <JCCIntakeForm
              config={{
                thankYouPage: thanks,
                name: formName,
                postUrl: `/jccintakeform`,
                consoleMessage: true
              }}
              title={title}
              fields={fields}
            />
          </Col>
        </Row>
      </Container>
    </Wrap>
  );
};

export default LpJccIntakeFormPage;
